<template>
  <div class="input-box">
    <label class="input-box__label" :for="formValues.id">{{ formValues.label }}</label>
    <div class="input-box__content">
      <input
        type="text"
        @input="handle($event)"
        class="input"
        :id="formValues.id"
        v-model="formValues.sectorName"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextInput',
  props: ['formValues'],
  methods: {
    handle(evt) {
      this.$emit('input', { newValue: evt.target.value })
    }
  }
}
</script>

<style scoped lang="sass">
.input-box
  position: relative
  margin-bottom: rem(30)
  &__label
    position: absolute
    left: 0
    transform: translate(rem(11), rem(-6))
    z-index: 2
    padding: 0 rem(4)
    @extend %12
    color: $text-gray
    background: $white
  &__content
    position: relative
    z-index: 1
    .icon
      height: 24px
      position: absolute
      transform: translate(rem(13), rem(15))
    input
      width: 100%
      @extend %16
      color: $main-text
      border: 1px solid $border-color
      border-radius: $block-radius
      +media((padding: (0: rem(15), 768: rem(18) rem(15))))
</style>
