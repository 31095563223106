<template>
  <section class="construction-types">
    <TextInput :formValues="sectorNameTitle" @input="update($event, 'name')" />
    <article class="construction-types__types-switcher">
      <form>
          <radio-button
            v-for="type in constructionTypes"
            :key="type.id"
            :selected="constructionType"
            :radioGroup="type"
            @input="update($event, 'constructionType', 'type')"
          />
      </form>
    </article>
    <article class="construction-types__attributes">
      <template v-for="dropdown in dropdowns">
        <OptionDropdown
          v-if="dropdown.viewCondition"
          :dropdown="dropdown"
          :options="dropdown.source"
          :key="dropdown.id"
          @update="update($event, 'constructionType', `${dropdown.id}`)"
        />
      </template>
    </article>
  </section>
</template>

<script>
import IconMixin from '@/components/mixin/IconMixin'
import TextInput from '@/components/dump/TextInput'
import RadioButton from '@/components/dump/RadioButton'
import OptionDropdown from '@/components/dump/OptionDropdown'
import { uuid } from 'vue-uuid'
import { mapGetters } from 'vuex'
import { isAccept, isDevMode, isFrame } from '@/api'

const images = {
  'Перекрытия': require('@/assets/img/floors.png'),
  'Стены и перегородки': require('@/assets/img/walls.png')
}

export default {
  name: 'ConstructionTypes',
  mixins: [IconMixin],
  props: ['sector'],
  components: { RadioButton, TextInput, OptionDropdown },
  data() {
    return {
      emptyOptions: [ { id: 0, value: '' } ],
      images: images
    }
  },
  computed: {
    ...mapGetters({
      getConstructionTypes: 'getConstructionTypes'
    }),
    sectorIndex() {
      return this.$store.state.sectors.findIndex((sector) => sector.id === this.sector.id)
    },
    sectorNameTitle() {
      return {
        label: this.$t('message.constructionTypes.input.label'),
        sectorName: this.sector.name ?? '',
        id: uuid.v1()
      }
    },
    constructionType() {
      return this.sector.constructionType.type
    },
    buildingType() {
      return this.sector.constructionType.buildingType
    },
    location() {
      return this.sector.constructionType.location
    },
    constructionTypes() {
      if (this.getConstructionTypes.length === 0) return this.getConstructionTypes

      const types = this.getConstructionTypes.map((type, index) => {
        return {
          id: index,
          img: images[type.constructionType],
          value: type.constructionType,
          name: this.$t('message.constructionTypes.types.title')
        }
      })

      const sortedTypes = types[0].value === this.$t('message.constructionTypes.types.walls')
        ? types
        : types.reverse()

      // Временно на прод выводится только один тип конструкций
      return isAccept || isDevMode || !isFrame ? sortedTypes : [ sortedTypes[0] ]
    },
    buildingTypes() {
      return this.constructionType && this.getConstructionTypes.length
        ? this.getConstructionTypes
          ?.find((type) => type.constructionType === this.constructionType)
          ?.buildings
          .map((buildingType, index) => {
            return {
              id: index,
              value: buildingType.name,
              ...buildingType
            }
          })
        : this.emptyOptions
    },
    locations() {
      return this.buildingType
        ? this.buildingTypes
          ?.find((location) => location.name === this.buildingType)
          ?.locations
          .map((location) => {
            return {
              id: location.id,
              value: location.title
            }
          })
        : this.emptyOptions
    },
    ratings() {
      const ratings = this.buildingType
        ? this.buildingTypes
          ?.find((buildingType) => buildingType.name === this.buildingType)?.ratings
        : null

      return ratings ? ratings.map((rating, index) => {
        return {
          id: index,
          value: rating
        }
      })
        : this.emptyOptions
    },
    isHotel() {
      return this.sector
        ? this.sector.constructionType.buildingType === this.$t('message.constructionTypes.buildings.types.hotel')
        : false
    },
    dropdowns() {
      return [
        {
          id: 'buildingType',
          label: this.$t('message.constructionTypes.buildings.title'),
          source: this.buildingTypes,
          viewCondition: true,
          selected: this.buildingTypes?.find((item) => item.value === this.buildingType)?.value,
          disabled: false
        },
        {
          id: 'rating',
          label: this.$t('message.constructionTypes.rating.title'),
          source: this.ratings,
          viewCondition: this.isHotel,
          selected: this.sector.constructionType.rating,
          disabled: !this.buildingType
        },
        {
          id: 'location',
          label: this.$t('message.constructionTypes.location.title'),
          source: this.locations,
          viewCondition: true,
          selected: this.locations?.find((item) => item.id === this.location?.id)?.value,
          disabled: !this.buildingType
        }
      ]
    }
  },
  methods: {
    update(evt, property, subproperty) {
      this.sector.isCalculated || this.sector.system
        ? this.editSector(evt, property, subproperty)
        : this.setFirstStepValues(evt, property, subproperty)
    },
    resetConstructionTypesValues(property, evt) {
      ['type', ...this.dropdowns.map(item => item.id)].forEach((subproperty) => {
        this.setConstructionTypesValues(property, subproperty, subproperty === 'type' ? evt.newValue : null)
      })
      this.$emit('checkStepValidation')
    },
    setConstructionTypesValues(property, subproperty, value) {
      this.$store.commit('UPDATE_SECTOR', {
        index: this.sectorIndex,
        property,
        subproperty,
        value
      })
    },
    editSector(evt, property, subproperty) {
      this.setFirstStepValues(evt, property, subproperty)
      this.$emit('checkStepValidation')
      this.setConstructionTypesValues('isCalculated', null, false)
    },
    setFirstStepValues(evt, property, subproperty) {
      subproperty === 'type'
        ? this.resetConstructionTypesValues(property, evt)
        : this.setConstructionTypesValues(property, subproperty, evt.newValue)
    }
  }
}
</script>

<style scoped lang="sass">
.construction-types
  width: 100%
  &__types-switcher
    form
      display: grid
      grid-template-rows: auto
      +media((grid-template-columns: (0: 1fr, 768: repeat(2, 1fr))))
      +media((grid-gap: (0: rem(10), 768: rem(20))))
      margin-bottom: rem(30)
  &__attributes
    display: grid
    grid-template-columns: 2fr 1fr
    +media((grid-template-areas: (0: 'buildingType buildingType' 'rating rating' 'location location', 992: 'buildingType buildingType rating' 'location location location')))
</style>
